<template>
  <loading v-if="$store.state.reserveout.status === 'fetching'"></loading>
  <v-container v-else class="pt-0 px-2">
    <template v-if="menus && menus.length > 0 && hasValidSections === true">
      <v-img
        :src="require('@/assets/byCategory_bg.png')"
        contain
        style="position: absolute; top: 0; right: 0"
      ></v-img>
      <back-toolbar title="reserveout.menu"></back-toolbar>
      <v-row class="mt-16">
        <v-col cols="12">
          <div class="HeadingWhiteH3-22Center mt-2" style="width: 100%">
            {{ $t(restaurantName) }}
          </div>
        </v-col>
      </v-row>
      <v-row class="ml-4" v-if="menus.length > 1">
        <v-col cols="12" class="pa-0">
          <v-layout class="pl-1" style="overflow: hidden; overflow-x: auto">
            <div v-for="(menu, index) in menus" v-bind:key="menu.name">
              <v-btn
                v-if="menu.sections && menu.sections.length > 0"
                class="mr-3 mt-3 mb-5 v-size--small menu text-capitalized"
                :color="selectedIndex === index ? 'primary' : ''"
                v-bind:class="{
                  'menu-selected gradient': selectedIndex === index,
                }"
                @click="selectedIndex = selectedIndex !== index ? index : null"
              >
                {{ menu.name.toLowerCase() }}
              </v-btn>
            </div>
          </v-layout>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col></v-col>
      </v-row>
      <v-row v-for="section in sections" v-bind:key="section.name">
        <v-col cols="12" class="py-0 mb-8">
          <div class="Heading-Dark-H4-18-Left-Align text-capitalized mx-4">
            {{ section.name.toLowerCase() }}
          </div>
          <v-divider class="mx-4"></v-divider>
          <div v-for="dish in section.dishes" v-bind:key="dish.name">
            <v-row class="mx-4 my-4">
              <ul class="pl-0">
                <li>
                  <div>
                    <span
                      class="name Heading-Dark-H5-16-Left-Align text-capitalized"
                    >
                      <span style="max-width: 80%">
                        {{ dish.name.toLowerCase() }}
                      </span>
                    </span>
                  </div>
                  <span class="price Heading-Dark-H5-16-Right-Align">
                    {{ dish.price }} {{ currency }}
                  </span>
                </li>
                <li v-if="dish.description">
                  <div class="text-capitalized mt-1">
                    {{ dish.description.toLowerCase() }}
                  </div>
                </li>
              </ul>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-btn
        id="pagetop"
        class="gradient scroll-button px-0"
        v-show="scY > 300"
        @click="toTop"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#fff"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="arcs"
        >
          <path d="M18 15l-6-6-6 6" />
        </svg>
      </v-btn>
    </template>
    <error-image
      v-else-if="$store.state.menu != 'fetching'"
      :errorDialog="true"
      :text="$t('reserveout.noMenu')"
      imageName="norecommendation.svg"
    ></error-image>
  </v-container>
</template>
<script>
import BackToolbar from "@/components/app/common/BackToolbar";
import ErrorImage from "@/components/app/common/ErrorImage";
import Loading from "@/components/app/common/Loading";

export default {
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    this.$store.commit("reserveout/REMOVE_MENU");
  },
  data: () => ({
    selectedIndex: 0,
    scTimer: 0,
    scY: 0,
  }),
  components: {
    Loading,
    BackToolbar,
    ErrorImage,
  },
  computed: {
    currency() {
      return this.$store.state.reserveout.menu[this.selectedIndex].currency;
    },
    menus() {
      return this.$store.state.reserveout.menu;
    },
    sections() {
      return this.$store.state.reserveout.menu[this.selectedIndex].sections;
    },
    hasValidSections() {
      for (var i = 0; i < this.$store.state.reserveout.menu.length; i++) {
        if (this.$store.state.reserveout.menu[i].sections.length > 0) {
          return true;
        }
      }
      return false;
    },
    selectedMenu() {
      return this.$store.state.reserveout.menu[this.selectedIndex];
    },
    restaurantName() {
      return this.$store.state.reserveout.restaurant.name;
    },
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style lang="scss">
.scroll-button {
  border-radius: 10px;
  margin-bottom: env(safe-area-inset-bottom);
  position: fixed;
  bottom: calc(70px + env(safe-area-inset-bottom));
  right: 20px;
  min-width: 36px !important;
  max-width: 36px !important;
  cursor: pointer;
}
ul {
  width: 100%;
}
li {
  display: flex;
  justify-content: space-between;
}
li > div {
  display: flex;
  flex: 1;
}
span.name::after {
  content: " ";
  flex: 1;
  border-bottom: 2px dotted #000;
  margin-bottom: 6px;
  margin-right: 4px;
  margin-left: 4px;
}
span.name {
  flex: 1;
  display: flex;
}
span.price {
  display: flex;
  align-items: flex-end;
}
.menu-item-name {
  max-width: 50%;
  display: inline-block;
}
.menu-item-fill {
  border: none;
  border-bottom: 2px dotted #000;
  display: inline-block;
}
.menu-item-price {
  display: inline-block;
}
.text-capitalized {
  text-transform: capitalize !important;
}
.menu,
.menu-selected {
  height: 34px !important;
  border-radius: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07) !important;
}
</style>
